import React, { Component } from 'react';

//import { observable, computed, reaction,action,decorate } from "mobx";
//import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

//import { connect } from "react-redux";

import { View, ActivityIndicator} from "react-native-web";






import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

//@inject("appStore")
//@observer
class LoadingPage extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {

   	};

    }


	 componentWillMount(){
   	  // document.body.style.backgroundImage = "#fff";
	 }

	 componentWillUnmount(){
    	//   document.body.style.backgroundImage = null;
	 }

   componentDidMount() {

   }







  render() {


    return (
    <Translation>
	    { t =>
	         <div>

      			<BrowserView>
                  <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center'}}>

                    <div className="main_header">
                      <div className="main_header_inside">
                        <div className="main_header_inside_left">
                            <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo"/>
                        </div>

                        <div className="main_header_inside_left_2" style={{display: 'none', width: '70%'}}>
                             <a href="/dplay" className="nonactiveHeaderLink">DPlay</a>
                            <a href="/about/dshop" className="nonactiveHeaderLink">DShop</a>
                            <a href="/about/dchat" className="nonactiveHeaderLink">DChat</a>
                            <a href="https://www.yinitj.com/" target="_blank" className="nonactiveHeaderLink">Youth&nbsp;Initiative&nbsp;Jeunesse</a>
                        </div>

                      </div>
                    </div>

                    <div style={{textAlign: 'center', marginBottom: '20px'}}>
                      	<img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '200px'}}/>
                    </div>
                    <ActivityIndicator  animating={true} color="#0079ff" />
                  </View>
      			</BrowserView>

      			{isMobileOnly &&
      			    <MobileView>

                   <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center'}}>

                          <div className="menu_container"  style={{display: 'none', marginTop: '0px',width: '35px'}}>
                                 <span className="top"></span>
                                 <span className="middle"></span>
                                 <span className="bottom"></span>
                             </div>

                               <div className="main_header" style={{top: '0px'}}>
                                      <div className="main_header_inside_mobile">
                                    <div className="main_header_inside_left_mobile">

                                            <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '120px'}}/>

                                    </div>

                                    <div className="main_header_inside_center_mobile">

                                    </div>

                                    <div className="main_header_inside_right_mobile">

                                    </div>

                              </div>
                          </div>

                          <div style={{textAlign: 'center', marginBottom: '20px'}}>
                             <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '200px'}}/>
                          </div>
                          <ActivityIndicator  animating={true} color="#0079ff" />
                     </View>

      			   </MobileView>
      			}

      			{isTablet &&
      			   <TabletView>

                  <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center'}}>

                    <div className="menu_container"  style={{display: 'none', marginTop: '0px',width: '35px'}}>
                           <span className="top"></span>
                           <span className="middle"></span>
                           <span className="bottom"></span>
                       </div>

                         <div className="main_header" style={{top: '0px'}}>
                                <div className="main_header_inside_mobile">
                              <div className="main_header_inside_left_mobile">

                                      <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '120px'}}/>

                              </div>

                              <div className="main_header_inside_center_mobile">

                              </div>

                              <div className="main_header_inside_right_mobile">

                              </div>

                        </div>
                    </div>



                    <div style={{textAlign: 'center', marginBottom: '20px'}}>
                       <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '200px'}}/>
                    </div>
                    <ActivityIndicator  animating={true} color="#0079ff" />
                  </View>

      			   </TabletView>
      			}


	        </div>
	  }
	 </Translation>
    )
  }
}





export default LoadingPage;
