import React, { Component } from 'react';
import loadable from "@loadable/component";

import PubSub from 'pubsub-js';

import { BrowserRouter, Route, Link, withRouter, Redirect  } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, Translation, } from 'react-i18next';
import PropTypes from 'prop-types';

import LoadingPage from "./components/pages/home/LoadingPage";

import {BrowserView,MobileView,TabletView,isBrowser,isMobileOnly, isTablet} from 'react-device-detect';

import { ActivityIndicator, Text, View } from 'react-native-web';

import { isLogin, profileExists, isReservedPath } from './utils';

import i18n from "./i18n";

import {generateIPForREST} from "./actions";

import setLanguage from "./actions/setLanguage";



const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;


const FeelsPop = loadable(() => import("./components/engagements/FeelsPop"));


const HomePage = loadable(() => import("./components/pages/home/HomePage"), {
     fallback: <LoadingPage />
  });



  const PostPop = loadable(() => import("./components/pages/post/PostPop"));
  const PostPage = loadable(() => import("./components/pages/post/PostPage"), {
       fallback: <LoadingPage />
    });


  const AllFacetsPop = loadable(() => import("./components/pages/profile/AllFacetsPop"));

    const StoryPage = loadable(() => import("./components/pages/story/StoryPage"), {
         fallback: <LoadingPage />
      });

  const PDFPopViewer = loadable(() => import("./components/pages/story/PDFPopViewer"));

  const PitchPage = loadable(() => import("./components/pages/story/PitchPage"), {
       fallback: <LoadingPage />
    });


    const DBPitchPage = loadable(() => import("./components/pages/story/DBPitchPage"), {
         fallback: <LoadingPage />
      });

    const PitchDeckPage = loadable(() => import("./components/pages/story/PitchDeckPage"), {
         fallback: <LoadingPage />
      });


  const OurVisionPage = loadable(() => import("./components/pages/story/OurVisionPage"), {
       fallback: <LoadingPage />
   });







  const ProfilePage = loadable(() => import("./components/pages/profile/ProfilePage"), {
       fallback: <LoadingPage />
    });
    const ProfileFollowersPage = loadable(() => import("./components/pages/profile/ProfileFollowersPage"), {
         fallback: <LoadingPage />
      });

    const ProfileFollowingPage = loadable(() => import("./components/pages/profile/ProfileFollowingPage"), {
         fallback: <LoadingPage />
      });

    const ProfileLinksPage = loadable(() => import("./components/pages/profile/ProfileLinksPage"), {
         fallback: <LoadingPage />
      });

      const ProfileXPage = loadable(() => import("./components/pages/profile/ProfileXPage"), {
           fallback: <LoadingPage />
        });

    const ProfileXPagePage = loadable(() => import("./components/pages/profile/ProfileXPagePage"), {
         fallback: <LoadingPage />
      });

    const ProfileFacetsPage = loadable(() => import("./components/pages/profile/ProfileFacetsPage"), {
         fallback: <LoadingPage />
      });

    const ProfileFacetPage = loadable(() => import("./components/pages/profile/ProfileFacetPage"), {
         fallback: <LoadingPage />
      });

   const ProfileStoriesPage = loadable(() => import("./components/pages/profile/ProfileStoriesPage"), {
        fallback: <LoadingPage />
     });

     const ProfileHeaderThumb = loadable(() => import("./components/pages/profile/ProfileHeaderThumb"), {
          fallback: <LoadingPage />
       });

  // GENERAL/STATIC //
  const CareersPage = loadable(() => import("./components/pages/general/CareersPage"), {
       fallback: <LoadingPage />
    });

  const RequestDemoPage = loadable(() => import("./components/pages/general/RequestDemoPage"), {
       fallback: <LoadingPage />
    });
  const DownloadDebyooPage = loadable(() => import("./components/pages/general/DownloadDebyooPage"), {
       fallback: <LoadingPage />
    });
  const ContactPage = loadable(() => import("./components/pages/general/ContactPage"), {
       fallback: <LoadingPage />
    });
  const OurmissionPage = loadable(() => import("./components/pages/general/OurmissionPage"), {
       fallback: <LoadingPage />
    });
  const TermsPage = loadable(() => import("./components/pages/general/TermsPage"), {
       fallback: <LoadingPage />
    });
  const EulaPage = loadable(() => import("./components/pages/general/EulaPage"), {
       fallback: <LoadingPage />
    });
  const PrivacyPage = loadable(() => import("./components/pages/general/PrivacyPage"), {
       fallback: <LoadingPage />
    });
  const AboutSongPage = loadable(() => import("./components/pages/general/AboutSongPage"), {
       fallback: <LoadingPage />
    });
  const AboutDShopPage = loadable(() => import("./components/pages/general/AboutDShopPage"), {
       fallback: <LoadingPage />
    });
  const AboutDChatPage = loadable(() => import("./components/pages/general/AboutDChatPage"), {
       fallback: <LoadingPage />
    });
  const SupportPage = loadable(() => import("./components/pages/general/SupportPage"), {
       fallback: <LoadingPage />
    });
  const SupportSystemStatusPage = loadable(() => import("./components/pages/general/SupportSystemStatusPage"));
  const Covid19 = loadable(() => import("./components/pages/general/Covid19"), {
       fallback: <LoadingPage />
    });



    const NewsroomPage = loadable(() => import("./components/pages/newsroom/NewsroomPage"), {
         fallback: <LoadingPage />
      });
    const NewsroomContentPage = loadable(() => import("./components/pages/newsroom/NewsroomContentPage"));

    const TheyTalkAboutUsPage = loadable(() => import("./components/pages/newsroom/TheyTalkAboutUsPage"), {
         fallback: <LoadingPage />
      });

  function removeURLParameter(url, parameter) {
      //prefer to use l.search if you have a location/link object
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {

          var prefix = encodeURIComponent(parameter) + '=';
          var pars = urlparts[1].split(/[&;]/g);

          //reverse iteration as may be destructive
          for (var i = pars.length; i-- > 0;) {
              //idiom for string.startsWith
              if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                  pars.splice(i, 1);
              }
          }

          return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
  }

class App extends Component {

    constructor(props) {

          super(props);

          this.handleClickOutsideVideoPop = this.handleClickOutsideVideoPop.bind(this);
          this.handleClickOutsidePDFViewerPop = this.handleClickOutsidePDFViewerPop.bind(this);

          this.videoPopWrapperRef = React.createRef();
          this.pdfViewerPopWrapperRef = React.createRef();

          var bgMode = localStorage.getItem("bgMode") || 'light-mode';
          if (bgMode == 'light-mode')
          {
            var _darkMode = false;
          }
          else
          {
             var _darkMode = true;
          }


          this.state = {
            countryCode: 'US',
            lastYPosition: 0,
            darkMode: _darkMode,
            pdfIsLoaded: false,
            displayPDFViewerPop: false,
            displayDownloadViewerPop: false,
            displayVideoPop: false,
            pdfURL: null,
            videoID: null,
            linkTo: '',
            newtypef: '',
            edittypef: '',
            checkedDarkmode: _darkMode,
       		 open: false,
       		 isSearch: false,
       		 isNewStory: false,
            isNewStoryPost: false,
            isEditStoryPost: false,
            isUploadThumbnail: false,
       		 toStartLiveVideo: false,
            postPopIsVisible: false,
            displayFeels: false,
            lastPathname: null,
            allFacetsPopIsVisible: false,
            currentUserHash: null,
            currentPostItem: null,
            currentPostID: null,
            currentPostType: null,
            currentPostTitle: null,
            currentPostDescription: null,
            currentPostPostTitles: null,
            currentPostPostDescriptions: null,
            currentPostPostLinks: null,
            currentPostIntl: null,
            currentPostOccDate: null,
            currentPostOccDateActivation: null,
            currentPostEventLocation: null,
            currentPostEventLocationActivation: null,
            currentPostURLMetadata: null,
            currentPostBGColor: null,
            currentPostFontFamily: null,
            currentStoryID: null,
            currentObjectType: null,
            currentObjectID: null,
            currentPluggedObjectType: null,
            currentPluggedObjectID: null,
            totalFeels: 0,
            displayGlobalError: false,
            totalNotifications: 0
          };


   }

   componentWillUnmount(){



          document.removeEventListener('mousedown', this.handleClickOutsideVideoPop);
          document.removeEventListener('mousedown', this.handleClickOutsidePDFViewerPop);

          PubSub.unsubscribe(this.pushNewBrowserHistoryVar);
          PubSub.unsubscribe(this.popBrowserHistoryVar);
          PubSub.unsubscribe(this.closeGlobalPopWindowVar);
          PubSub.unsubscribe(this.openPostPopVar);
          PubSub.unsubscribe(this.openAllFacetsPop);
          PubSub.unsubscribe(this.viewFeelsPopVar);
          PubSub.unsubscribe(this.handleLanguageChangeVar);
          PubSub.unsubscribe(this.launchVideoPopVar);
          PubSub.unsubscribe(this.openPDFViewerPopVar);
          PubSub.unsubscribe(this.onPdfIsLoadedVar);
          PubSub.unsubscribe(this.openDownloadViewerPopVar);

    }


    componentDidMount() {

      document.addEventListener('mousedown', this.handleClickOutsideVideoPop);
       document.addEventListener('mousedown', this.handleClickOutsidePDFViewerPop);

       this.pushNewBrowserHistoryVar = PubSub.subscribe('pushNewBrowserHistory', this.onPushNewBrowserHistory.bind(this));
       this.popBrowserHistoryVar = PubSub.subscribe('popBrowserHistory', this.onPopBrowserHistory.bind(this));
       this.closeGlobalPopWindowVar = PubSub.subscribe('closeGlobalPopWindow', this.onCloseGlobalPopWindow.bind(this));
       this.openPostPopVar = PubSub.subscribe('openPostPop', this.onOpenPostPop.bind(this));
       this.openAllFacetsPop = PubSub.subscribe('openAllFacetsPop', this.onOpenAllFacetsPop.bind(this));
       this.viewFeelsPopVar = PubSub.subscribe('viewFeelsPop', this.onViewFeelsPop.bind(this));
       this.handleLanguageChangeVar = PubSub.subscribe('handleLanguageChange', this.onHandleLanguageChange.bind(this));
       this.openPDFViewerPopVar = PubSub.subscribe('openPDFViewerPop', this.onOpenPDFViewerPop.bind(this));
       this.onPdfIsLoadedVar = PubSub.subscribe('pdfIsLoaded', this.onPdfIsLoaded.bind(this));
       this.openDownloadViewerPopVar = PubSub.subscribe('openDownloadViewerPop', this.onOpenDownloadViewerPop.bind(this));

       this.launchVideoPopVar = PubSub.subscribe('launchVideoPop', this.onLaunchVideoPop.bind(this));


       this.handleChangeDarkmode = this.handleChangeDarkmode.bind(this);



       const queryString = window.location.search;
       const urlParams = new URLSearchParams(queryString);
       const lang = urlParams.get('l');

    //   alert(localStorage.getItem("language"))

       if (lang !== null && lang !== undefined && lang !== '')
       {
           if (lang == "fr_FR" || lang == "fr")
           {
              this.setLocale("fr");
           }
           else if (lang == "en_US" || lang == "en")
           {
              this.setLocale("en");
           }


       }
       else if (localStorage.getItem("language") !== null)
       {
             var _lang = localStorage.getItem('language');

             i18n.changeLanguage(_lang);


       }
       else
       {



               fetch('https://api.ipstack.com/check?access_key=022203c728a84a2d30f048b35db24a6e')
           		  	.then((response) => response.json())
           		  	.then((responseData) => {
           			  	//alert("App. Store: " + JSON.stringify(responseData.countryCode));
                     this.setState({
                       countryCode: responseData.country_code
                     });

           				  localStorage.setItem("geoLocation", responseData);

           				  if (isLogin())
           				  {

           					  if (localStorage.getItem('language') === null)
           					  {
           						  this.getLanguageFromServer(responseData.country_code);


           					  }
           					  else
           					  {
           						  var _lang = localStorage.getItem('language');
           						 // console.log("Got language from localStore: " + _lang);
           					  	  i18n.changeLanguage(_lang);
           					  }
           				  }
           				  else
           				  {
                       //  alert(responseData.countryCode)
           				  	  this.props.setLanguage(responseData.country_code);
                        //this.props.setLanguage('US');

           				  }


           				  // .country_code
           		      })
           		     .catch(function(error) {
           			      // alert(error);

           				   var _country_code  = "US", _lang = 'en';

             				  if (isLogin())
             				  {
             					  if (localStorage.getItem('language') === null)
             					  {

           						   this.getLanguageFromServer(_country_code);
             					  }
             					  else
             					  {
             						  var _lang = localStorage.getItem('language');
             						//  console.log("Got language from localStore: " + _lang);
             					  	  i18n.changeLanguage(_lang);
             					  }
             				  }
             				  else
             				  {
             				  	  this.props.setLanguage('US');
             				  }


           		     }.bind(this));

         }


     }

     onOpenDownloadViewerPop = (msg,data) => {
       this.setState({
         displayDownloadViewerPop: true
       });

     }

     closeDownloadPop() {
       this.setState({
         displayDownloadViewerPop: false
       });
     }

     onPdfIsLoaded = (msg,data) => {
       this.setState({
         pdfIsLoaded: true
       });
     }

     onOpenPDFViewerPop = (msg,data) => {

       var scrollTop = window.pageYOffset || document.documentElement.scrollTop;


        this.setState({
          lastYPosition: scrollTop,
          pdfIsLoaded: false,
          displayPDFViewerPop: true,
          pdfURL: data
        });

        document.body.style.overflow = "hidden";

        if (isMobileOnly || isTablet)
        {
            setTimeout(function(){
                document.body.style.position = "fixed";
            },200);
        }

     }

     closePDFViewerPop() {

        if (isMobileOnly || isTablet)
        {
          document.body.style.position = "relative";
          window.scrollTo({ top: this.state.lastYPosition});
        }

       document.body.style.overflow = "auto";

       this.setState({
         pdfIsLoaded: false,
         displayPDFViewerPop: false,
         pdfURL: null
       });
     }


     onHandleLanguageChange = (msg,data) => {
       if (data == "fr" || data == "fr_FR")
       {
         var lq = "l=fr_FR";
       }
       else
       {
          var lq = "l=en_US";
       }

       const path = this.props.history.location.pathname;

       const queryString = window.location.search;
       const urlParams = new URLSearchParams(queryString);



       var _total = 0;

       urlParams.forEach(function(value, key) {
          _total++;
       });



        if (urlParams !== undefined && urlParams  !== null && urlParams.toString() !== "")
        {
           if (urlParams.has('l'))
           {
               if (_total > 1)
               {
                  var url = path + '?' + urlParams;
                  url = removeURLParameter(url, 'l');
                  url = url + '&' + lq;
               }
               else
               {
                 var url = path + '?' + urlParams;
                 url = removeURLParameter(url, 'l');
                 url = url + '?' + lq;
               }
           }
           else
           {
              var url = path + '?' + urlParams + '&' + lq;
           }
        }
        else
        {
           var url = path + '?' + lq;
        }

        this.props.history.push(url);



     }



     setLocale(lang) {

       console.log("Locale Lang:" +lang);

       localStorage.setItem("language", lang);
       i18n.changeLanguage(lang);





       if (isLogin())
       {
         this.saveLanguageToServer(lang);
       }
       //  this.props.appStore.locale = lang;
     }


     saveLanguageToServer(lang) {
       //var ip = "https://www.debyoo.com/";
       var ip = generateIPForREST();

       var jwtToken = localStorage.getItem("jwtToken") || '';
       //  this.props.setLanguage(responseData.country_code);
       fetch(ip+'save_language_to_server_api', {
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Authorization': jwtToken,
           'Content-Type': 'application/json'
         },
         credentials: 'include',
         body: JSON.stringify({
           language: lang
         })
       })
       .then((response) => response.json())
       .then((responseData) => {
         console.log("Language saved on server");

       })
       .catch(function(error) {

       }.bind(this));

     }


     handleChangeDarkmode(checked) {


         if (checked)
         {
           document.body.style.backgroundColor = "#18191a";
           document.body.style.color = "#e4e5e6";

           localStorage.setItem("bgMode", 'dark-mode');
         }
         else
         {
           document.body.style.backgroundColor = "#e4e5e6";
           document.body.style.color = "#333";

           localStorage.setItem("bgMode", 'light-mode');
         }



         this.setState({
           checkedDarkmode: checked,
           darkMode: checked
         });
     }


     getLanguageFromServer(_country_code) {
   		//var ip = "https://www.debyoo.com/";
   		var ip = generateIPForREST();

   		var jwtToken = localStorage.getItem("jwtToken") || '';
   		//  this.props.setLanguage(responseData.country_code);
   		fetch(ip+'get_language_from_server_api', {
                                 method: 'POST',
                                 headers: {
                                   'Accept': 'application/json',
   				    'Authorization': jwtToken,
                                   'Content-Type': 'application/json'
                                 },
   				   credentials: 'include',
                                 body: JSON.stringify({
                                 })
                         })
   		  	.then((response) => response.json())
   		  	.then((responseData) => {
   				//console.log("Got language from server: " + responseData.language);
   				 i18n.changeLanguage(responseData.language);
   	             })
   	            .catch(function(error) {
   				     this.props.setLanguage(_country_code);
   		     }.bind(this));
   	}


     requireLogin = (indexState, replace) => {

    // console.log(">>> dest: " + indexState.location.pathname)

       if (! isLogin()) {
         var destinationPath = indexState.location.pathname;
         replace({
           pathname: '/signin',
           query: {destinationPath}
         });
       }
      };


     onViewFeelsPop = (msg,data) => {
          document.body.style.overflow = "hidden";

          this.setState({
            displayFeels: true,
            currentObjectType: data.object_type,
            currentObjectID: data.object_id,
            totalFeels: data.total,
          });
     }


     onPushNewBrowserHistory = (msg,data) => {
         this.props.history.push(data)
     }

     onPopBrowserHistory = (msg,data) => {
         this.props.history.replace(data)
     }

     onOpenAllFacetsPop = (msg,data) => {
       this.setState({
        isNewStory: false,
         isNewStoryPost: false,
         isUploadThumbnail: false,
        toStartLiveVideo: false,
         postPopIsVisible: false,
         allFacetsPopIsVisible: true,
         lastPathname: data.pathname,
         currentUserHash: data.userHash,
         linkTo: data.linkTo
      });

       document.body.style.overflow = "hidden";
       this.props.history.push(data.linkTo);

    }


     onOpenPostPop = (msg,data) => {
    //   alert(data.pathname + '  / ' + data.linkTo)
   // console.log(">>>  data:: "  + JSON.stringify(data));
       this.setState({
 				isNewStory: false,
         isNewStoryPost: false,
         isUploadThumbnail: false,
 				toStartLiveVideo: false,
         postPopIsVisible: true,
         lastPathname: data.pathname,
         currentPostItem: data.item,
         linkTo: data.linkTo
 			});

       document.body.style.overflow = "hidden";
       this.props.history.push('/p/'+data.item.id);
     }

 	onCloseGlobalPopWindow  = (msg, data) => {
 	//	alert('>>> Resp : ' + JSON.stringify(msg) + ' /// ' +  JSON.stringify(data));
 	      if (data.typef == 'live video')
 		   {
          document.body.style.overflow = "auto";

    			this.setState({
    				isSearch: false,
           isNewStoryPost: false,
           isUploadThumbnail: false,
    				isNewStory: false,
    				toStartLiveVideo: false,
           postPopIsVisible: false,
    			});
 		   }
    		 else if (data.typef == "live video watcher")
    		 {
            document.body.style.overflow = "auto";
    		     this.props.history.push('/'+data.screenname)
    		  }
         else if (data.typef == 'all facets')
        {
             document.body.style.overflow = "auto";

             if (data.version !== "strict")
             {
                this.props.history.push(this.state.lastPathname);
              }

            this.setState({
              isNewStory: false,
               isNewStoryPost: false,
               isUploadThumbnail: false,
              toStartLiveVideo: false,
               postPopIsVisible: false,
               allFacetsPopIsVisible: false,
            });
        }
         else if (data.typef == 'post pop')
        {
           if (this.state.isSearch)
           {
                this.props.history.push(this.state.lastPathname);

                this.setState({
                  isNewStory: false,
                  isNewStoryPost: false,
                  isUploadThumbnail: false,
                  toStartLiveVideo: false,
                 postPopIsVisible: false,
                 lastPathname: null,
                 currentPostItem: null
                });
           }
           else
           {
                 document.body.style.overflow = "auto";
                 this.props.history.push(this.state.lastPathname);

                 this.setState({
                   isNewStory: false,
                   isNewStoryPost: false,
                   isUploadThumbnail: false,
                   toStartLiveVideo: false,
                  postPopIsVisible: false,
                  lastPathname: null,
                  currentPostItem: null
                 });
           }

       }
       else
       {
           document.body.style.overflow = "auto";

          this.setState({
            isSearch: false,
            isNewStoryPost: false,
            isNewStory: false,
            isUploadThumbnail: false,
            toStartLiveVideo: false,
            postPopIsVisible: false,
            lastPathname: null,
            currentPostItem: null
          });
       }
 	}


     relayCallbackHeader = (dataFromHeader) => {
   		//console.log("GREAT!! Got it...: " + dataFromHeader);

   		if (dataFromHeader == "search")
   		{
   			this.setState({
   				isSearch: true,
           isNewStoryPost: false,
           isUploadThumbnail: false,
   				isNewStory: false,
   				toStartLiveVideo: false,
           postPopIsVisible: false,
   			});

         document.body.style.overflow = "hidden";
   		}
   		else if (dataFromHeader == "newstory")
   		{
   			this.setState({
   				isSearch: false,
           isNewStoryPost: false,
           isUploadThumbnail: false,
   				isNewStory: true,
   				toStartLiveVideo: false,
           postPopIsVisible: false,
   			});

         document.body.style.overflow = "hidden";
   		}
       else if (dataFromHeader == "uploadthumbnail")
       {
           this.setState({
            isSearch: false,
            isNewStoryPost: false,
            isNewStory: false,
            isUploadThumbnail: true,
            toStartLiveVideo: false,
             postPopIsVisible: false,
          });

           document.body.style.overflow = "hidden";
       }
   		else if (dataFromHeader == "live video")
   		{
   			this.setState({
   				isSearch: false,
           isNewStoryPost: false,
   				isNewStory: false,
           isUploadThumbnail: false,
   				toStartLiveVideo: true,
           postPopIsVisible: false,
   			});

         document.body.style.overflow = "hidden";

   			//this.props.history.push('/live/broadcast');
   		}
       else if (dataFromHeader == "post")
   		{
   			this.setState({
   				isSearch: false,
           isNewStoryPost: false,
   				isNewStory: false,
           isUploadThumbnail: false,
   				toStartLiveVideo: false,
           postPopIsVisible: true,
   			});

         document.body.style.overflow = "hidden";

   			//this.props.history.push('/live/broadcast');
   		}
   		else if (dataFromHeader == "close live video" || dataFromHeader == "close post")
   		{
   			this.setState({
   				isSearch: false,
           isNewStoryPost: false,
   				isNewStory: false,
           isUploadThumbnail: false,
   				toStartLiveVideo: false,
           postPopIsVisible: false,
   			});

         document.body.style.overflow = "auto";

   			//this.props.history.push('/live/broadcast');
   		}
   		else
   		{
   			this.setState({
   				isSearch: false,
           isNewStoryPost: false,
   				isNewStory: false,
           isUploadThumbnail: false,
   				toStartLiveVideo: false,
           postPopIsVisible: false,
   			});

         document.body.style.overflow = "auto";
   		}
        }



        closeFeelsPopCallback = () =>  {

          this.setState({
            displayFeels: false
          });

          document.body.style.overflow = "auto";

        }

     handleClickOutsideVideoPop(event) {
       if (isBrowser)
       {
           setTimeout (function(){
             if (this.videoPopWrapperRef && this.videoPopWrapperRef.current !== null && !this.videoPopWrapperRef.current.contains(event.target)) {
               this.setState({
                 displayVideoPop: false,
                 videoID: null
               });
             }
           }.bind(this), 100);
      }

     }

     handleClickOutsidePDFViewerPop(event) {
       if (isBrowser)
       {
         if (this.state.displayPDFViewerPop && this.pdfViewerPopWrapperRef && this.pdfViewerPopWrapperRef.current !== null && !this.pdfViewerPopWrapperRef.current.contains(event.target)) {
          document.body.style.overflow = "auto";
           this.setState({
             displayPDFViewerPop: false,
             pdfURL: null
           });
         }
      }

     }


     showVideoPop() {
       this.setState({
         displayVideoPop: true
       });
     }

     closeVideoPop() {


       this.setState({
         displayVideoPop: false,
         videoID: null
       });
     }

     onLaunchVideoPop = (msg,data) => {

      // alert(data)

        this.setState({
          displayVideoPop: true,
          videoID: data
        });
     }


   render () {

     let store = this.props.store;
	   const { history } = this.props;

             return (
               <Translation>
              {
                  t =>
                 <BrowserRouter>

                      <div className={this.state.darkMode ? "dark-mode":"light-mode"} style={{fontFamily: 'Lato'}}>



                        {this.state.displayDownloadViewerPop &&
                          <div style={{position: 'fixed', width: '100%', height:  '100%',  top:  0, left: 0, backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 9999999999999999}}>



                             <div onClick={this.closeDownloadPop.bind(this)} style={{position: 'absolute', zIndex: 0, width: '100%', height:  '100%',  backgroundColor: 'transparent'}}></div>


                            {isMobileOnly  &&
                              <div onClick={this.closeDownloadPop.bind(this)} style={{position: 'fixed', zIndex: 99999, backgroundColor: '#fff', borderRadius: '50%',
                                right: '15px', top: '15px', color: '#fff', width: '36px', height: '36px', textAlign: 'center', lineHeight: '36px',
                                boxShadow: '-1px 0px 5px 2px rgba(0,0,0,0.85)'}}>
                                  <div style={{marginTop: '3px'}}><img src={'https://d3van47cb9u9tj.cloudfront.net/img/close_btn_grey.png'} style={{width: '30px'}}/></div>
                              </div>

                            }

                            {isMobileOnly  &&
                              <div style={{position: 'absolute', background:this.state.darkMode?'#111':'#fafafa', boxShadow: this.state.darkMode?'-1px 0px 11px 2px rgba(27,28,26,0.75)':'-1px 0px 11px 2px rgba(0,0,0,0.75)',
                                  zIndex: 9,  borderRadius: 10, textAlign: 'center',
                                  width: '340px', top: '80px', left: '50%', marginLeft: '-170px'}}>

                                  <div  style={{position:'relative', margin: 'auto', width: '100%'}}>

                                    <div className="white-round-container" style={{marginTop: 0, position:'relative', width: '100%'}}>

                                              <div style={{backgroundColor: '#ff2001', minHeight: '40', width: '100%', borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}}>
                                                    {i18n.language == 'fr' &&
                                                       <div style={{padding: '10px', color: '#fff', fontSize: '14px'}}>
                                                          Cette opération est actuellement disponible uniquement via l'application Debyoo
                                                       </div>
                                                    }
                                                    {i18n.language == 'en' &&
                                                       <div style={{padding: '10px', color: '#fff', fontSize: '14px'}}>
                                                          This operation is currently available only via Debyoo app
                                                       </div>
                                                    }
                                              </div>

                                              <div className="padding-20">

                                                  <div className="page-title" style={{fontSize: '18px'}}>
                                                                          {t('Download')} {'Debyoo'}
                                                   </div>


                                                    <div style={{clear: 'both', height: '10px'}} />
                                                    <div style={{clear: 'both', width: '100%', borderTop: '1px solid #ccc'}} />
                                                    <div style={{clear: 'both', height: '20px'}} />

                                                    <div style={{textAlign: 'center', marginTop: '0px'}}>
                                                       {i18n.language == "fr" &&
                                                           <a onClick={this.closeDownloadPop.bind(this)}  href={'https://itunes.apple.com/fr/app/debyoo/id1460835118'} target="_blank">
                                                               <img src={'https://s3.amazonaws.com/debyooinc20.img/apple_store_download.png'} style={{width: '150px'}} />
                                                           </a>
                                                       }
                                                        {i18n.language == "en" &&
                                                        <a onClick={this.closeDownloadPop.bind(this)} href={'https://itunes.apple.com/app/debyoo/id1460835118'} target="_blank">
                                                          <img src={'https://s3.amazonaws.com/debyooinc20.img/apple_store_download.png'} style={{width: '150px'}} />
                                                        </a>
                                                       }
                                                    </div>

                                                    <div style={{textAlign: 'center', marginTop: '10px'}}>

                                                        <a onClick={this.closeDownloadPop.bind(this)} href={'https://play.google.com/store/apps/details?id=com.androidv.debyooapp&hl='+i18n.language} target="_blank">
                                                          <img src={'https://s3.amazonaws.com/debyooinc20.img/google_store_download.png'} style={{width: '150px'}} />
                                                        </a>
                                                    </div>




                                                  <div className="clear-both" style={{clear: 'both', height: '10px'}}/>



                                            </div>



                                     </div>


                                   </div>

                             </div>
                            }

                            {(isBrowser || isTablet)  &&
                              <div onClick={this.closeDownloadPop.bind(this)} style={{cursor: 'pointer', position: 'fixed', zIndex: 99999,  backgroundColor: '#fff', borderRadius: '50%',
                                right: '20px', top: '20px', color: '#fff', width: '56px', height: '56px', textAlign: 'center', lineHeight: '56px',
                                boxShadow: '-1px 0px 5px 2px rgba(0,0,0,0.75)'}}>
                                   <div style={{marginTop: '3px'}}><img src={'https://d3van47cb9u9tj.cloudfront.net/img/close_btn_grey.png'} style={{width: '50px'}}/></div>
                              </div>

                            }


                            {(isBrowser || isTablet) &&
                              <div style={{position: 'absolute', background:this.state.darkMode?'#111':'#fafafa', boxShadow: this.state.darkMode?'-1px 0px 11px 2px rgba(27,28,26,0.75)':'-1px 0px 11px 2px rgba(0,0,0,0.75)',
                                  zIndex: 9,  borderRadius: 10, textAlign: 'center',
                                  width: '660px', height: '150px', top: '130px', left: '50%', marginLeft: '-330px'}}>

                                  <div  style={{position:'relative', margin: 'auto', width: '100%'}}>

                                    <div className="white-round-container" style={{marginTop: 0, position:'relative', width: '100%'}}>


                                              <div style={{backgroundColor: '#ff2001', minHeight: '40', width: '100%', borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}}>
                                                    {i18n.language == 'fr' &&
                                                       <div style={{padding: '20px', color: '#fff', fontSize: '18px'}}>
                                                          Cette opération est actuellement disponible uniquement via l'application Debyoo
                                                       </div>
                                                    }
                                                    {i18n.language == 'en' &&
                                                       <div style={{padding: '20px', color: '#fff', fontSize: '18px'}}>
                                                          This operation is currently available only via Debyoo app
                                                       </div>
                                                    }
                                              </div>

                                              <div className="padding-20">

                                                  <div className="page-title" style={{fontSize: '20px'}}>
                                                                          {t('Download')} {'Debyoo'}
                                                   </div>


                                                    <div style={{clear: 'both', height: '10px'}} />
                                                    <div style={{clear: 'both', width: '100%', borderTop: '1px solid #ccc'}} />
                                                    <div style={{clear: 'both', height: '30px'}} />

                                                    <div style={{textAlign: 'center', marginTop: '40px'}}>
                                                          {i18n.language == "fr" &&
                     		   			   										       <a onClick={this.closeDownloadPop.bind(this)} href={'https://itunes.apple.com/fr/app/debyoo/id1460835118'} target="_blank">
                     		   			   										           <img src={'https://s3.amazonaws.com/debyooinc20.img/apple_store_download.png'} style={{width: '250px'}} />
                     		   			   										       </a>
                     		   			   										   }
                     		   			   										    {i18n.language == "en" &&
                     		   			   										    <a onClick={this.closeDownloadPop.bind(this)} href={'https://itunes.apple.com/app/debyoo/id1460835118'} target="_blank">
                     		   			   										      <img src={'https://s3.amazonaws.com/debyooinc20.img/apple_store_download.png'} style={{width: '250px'}} />
                     		   			   										    </a>
                     		   			   										   }
                     		   			   										    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     		   			   										    <a onClick={this.closeDownloadPop.bind(this)} href={'https://play.google.com/store/apps/details?id=com.androidv.debyooapp&hl='+i18n.language} target="_blank">
                     		   			   										      <img src={'https://s3.amazonaws.com/debyooinc20.img/google_store_download.png'} style={{width: '250px'}} />
                     		   			   										    </a>
                                                    </div>






                                                  <div className="clear-both" style={{clear: 'both', height: '70px'}}/>



                                            </div>



                                     </div>


                                   </div>

                             </div>
                            }

                         </div>
                      }



                      {this.state.displayPDFViewerPop &&
                        <div style={{position: 'fixed', width: '100%', height:  '100%',  top:  0, left: 0, backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 9999999999999999}}>

                          {isMobileOnly  &&
                            <div onClick={this.closePDFViewerPop.bind(this)} style={{position: 'fixed', zIndex: 99999, backgroundColor: '#fff', borderRadius: '50%',
                              right: '15px', top: '15px', color: '#fff', width: '36px', height: '36px', textAlign: 'center', lineHeight: '36px',
                              boxShadow: '-1px 0px 5px 2px rgba(0,0,0,0.85)'}}>
                                <div style={{marginTop: '3px'}}><img src={'/img/close_btn_grey.png'} style={{width: '30px'}}/></div>
                            </div>

                          }

                          {isTablet  &&
                            <div onClick={this.closePDFViewerPop.bind(this)} style={{position: 'fixed', zIndex: 99999,  backgroundColor: '#fff', borderRadius: '50%',
                              right: '20px', top: '20px', color: '#fff', width: '56px', height: '56px', textAlign: 'center', lineHeight: '56px',
                              boxShadow: '-1px 0px 5px 2px rgba(0,0,0,0.75)'}}>
                                 <div style={{marginTop: '3px'}}><img src={'/img/close_btn_grey.png'} style={{width: '50px'}}/></div>
                            </div>

                          }

                          <div style={{position: 'relative', width: '100%', height:  '100%',  backgroundColor: 'transparent'}}>

                            {!this.state.pdfIsLoaded && !isMobileOnly  && !isTablet &&
                              <div style={{position: 'absolute', zIndex: 3, width: '824px', height:  '100%', textAlign: 'center',
                                      top: '0.25em', left: '50%', marginLeft: '-412px',  backgroundColor: '#fff'}}>
                                <div style={{height: '40%'}}></div>
                                  <div style={{width: '824px', margin: 'auto', textAlign: 'center', backgroundColor: 'transparent'}}>
                                      <img src={'/img/default_story_thumb_alone_transp.png'} style={{width: '220px', opacity: 0.3}}/>
                                   </div>
                              </div>
                             }

                             {!this.state.pdfIsLoaded && isMobileOnly  &&
                               <div style={{position: 'absolute', zIndex: 3, width: '98%', height:  '98%', textAlign: 'center',
                                       top: '0.25em', left: '1%', backgroundColor: '#fff'}}>
                                 <div style={{height: '40%'}}></div>
                                   <div style={{width: '100%', margin: 'auto', textAlign: 'center', backgroundColor: 'transparent'}}>
                                       <img src={'/img/default_story_thumb_alone_transp.png'} style={{width: '120px', opacity: 0.3}}/>
                                    </div>
                               </div>
                              }

                              {!this.state.pdfIsLoaded && isTablet &&
                                <div style={{position: 'absolute', zIndex: 3, width: '90%', height:  '95%', textAlign: 'center',
                                        top: '0.25em', left: '5%', backgroundColor: '#fff'}}>
                                  <div style={{height: '40%'}}></div>
                                    <div style={{width: '100%', margin: 'auto', textAlign: 'center', backgroundColor: 'transparent'}}>
                                        <img src={'/img/default_story_thumb_alone_transp.png'} style={{width: '220px', opacity: 0.3}}/>
                                     </div>
                                </div>
                               }

                               {!isMobileOnly  && !isTablet &&
                                 <div onClick={this.closePDFViewerPop.bind(this)} style={{position: 'absolute', zIndex: 0, width: '100%', height:  '100%',  backgroundColor: 'transparent'}}></div>
                                }

                                {!isMobileOnly && !isTablet &&
                                  <div onClick={this.closePDFViewerPop.bind(this)} style={{cursor: 'pointer',  position: 'absolute', zIndex: 99, right: '20px', top: '0px', color: '#fff', fontSize: '70px', fontWeight: 'normal', fontFamily: 'Lato'}}>&times;</div>
                                }


                                {isMobileOnly  &&
                                  <div style={{position: 'absolute', zIndex: 0, width: '100%', height:  '100%',  backgroundColor: 'transparent'}}></div>
                                 }



                                {isTablet  &&
                                  <div style={{position: 'absolute', zIndex: 0, width: '100%', height:  '100%',  backgroundColor: 'transparent'}}></div>
                                 }



                               {this.state.pdfURL !== null &&
                                     <PDFPopViewer url={this.state.pdfURL}/>
                                }

                                <div style={{height: '10px'}}></div>

                           </div>
                       </div>
                     }


                         <div id="app_main_id" className="app_main_wrapper_rel">



                                   {this.state.displayGlobalError &&
                                      <div style={{position: 'absolute', background:this.state.darkMode?'#111':'#fafafa', boxShadow: this.state.darkMode?'-1px 0px 11px 2px rgba(27,28,26,0.75)':'-1px 0px 11px 2px rgba(0,0,0,0.75)',
                                          zIndex: 9,  borderRadius: 10, textAlign: 'center',
                                          width: '500px', height: '150px', top: '150px', left: '50%', marginLeft: '-250px'}}>
                                          <div style={{padding: '20px'}}>
                                            <View style={{width: 460, height: 110, alignSelf: 'center', justifyContent: 'center'}}>
                                                <Text style={{color: this.state.darkMode?'#ff5c49':'#ff2200', fontSize: 16, textAlign: 'center'}}>{t('An_error_occurred_while_processing_your_request')}</Text>
                                            </View>
                                         </div>
                                     </div>
                                   }


                                {this.state.displayVideoPop  &&
                                  <div style={{position: 'fixed', width: '100%', height:  '100%', top:  0, left: 0, overflow: 'hidden', backgroundColor: '#fff', zIndex: 9999999999999999}}>

                                     <div style={{position: 'relative', width: '100%', height:  '100%',backgroundColor: '#fff'}}>

                                      {isBrowser &&
                                         <div style={{position: 'absolute', zIndex: 99, left: '20px', top: '20px',}}>
                                                 <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '170px'}}/>
                                         </div>
                                      }
                                      {isMobileOnly &&
                                         <div style={{position: 'absolute', zIndex: 99, left: '20px', top: '30px',}}>
                                                 <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '140px'}}/>
                                         </div>
                                      }
                                      {isTablet &&
                                         <div style={{position: 'absolute', zIndex: 99, left: '20px', top: '20px',}}>
                                                 <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '170px'}}/>
                                         </div>
                                      }

                                       <div onClick={this.closeVideoPop.bind(this)} style={{cursor: 'pointer',  position: 'absolute', zIndex: 99, right: '20px', top: '0px', color: '#999', fontSize: '70px', fontWeight: 'normal', fontFamily: 'Lato'}}>&times;</div>

                                          <View style={{position: 'relative', width: '100%', height: '100%', justifyContent: 'center'}}>

                                             {isBrowser &&
                                                <div  ref={this.videoPopWrapperRef} style={{margin:'auto', background:  '#f1f1f1', width: (viewportHeight*0.7*16/9)+'px', height: (viewportHeight*0.7)+'px'}}>
                                                   {this.state.videoID !== null &&
                                                      <iframe width={viewportHeight*0.7*16/9} height={viewportHeight*0.7}
                                                       src={'https://www.youtube.com/embed/'+this.state.videoID+'?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1'} frameborder="0" allowfullscreen></iframe>
                                                  }
                                               </div>
                                             }

                                             {isMobileOnly &&
                                                <div  ref={this.videoPopWrapperRef} style={{margin:'auto', background:  '#f1f1f1', width: (viewportWidth*0.9)+'px', height: (viewportWidth*0.9*9/16)+'px'}}>
                                                   {this.state.videoID !== null &&
                                                      <iframe width={viewportWidth*0.9} height={viewportWidth*0.9*9/16}
                                                       src={'https://www.youtube.com/embed/'+this.state.videoID+'?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1'} frameborder="0" allowfullscreen></iframe>
                                                  }
                                               </div>
                                             }

                                             {isTablet &&
                                                <div  ref={this.videoPopWrapperRef} style={{margin:'auto', background:  '#f1f1f1', width: (viewportWidth*0.9)+'px', height: (viewportWidth*0.9*9/16)+'px'}}>
                                                   {this.state.videoID !== null &&
                                                      <iframe width={viewportWidth*0.9} height={viewportWidth*0.9*9/16}
                                                       src={'https://www.youtube.com/embed/'+this.state.videoID+'?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1'} frameborder="0" allowfullscreen></iframe>
                                                  }
                                               </div>
                                             }

                                          </View>

                                     </div>

                                 </div>
                               }





                               {this.state.postPopIsVisible &&
                                 <PostPop ref={this.postPopRef}  darkMode={this.state.darkMode}  linkTo={this.state.linkTo} path={this.props.path} store={this.props.store} currentPostItem={this.state.currentPostItem}/>
                               }

                               {this.state.allFacetsPopIsVisible && this.state.currentUserHash !== null &&
                                 <AllFacetsPop ref={this.postPopRef}  darkMode={this.state.darkMode}  linkTo={this.state.linkTo} path={this.props.path} store={this.props.store} currentUserHash={this.state.currentUserHash}/>
                               }

                               {this.state.displayFeels &&
                                 <FeelsPop darkMode={this.state.darkMode} object_type={this.state.currentObjectType} object_id={this.state.currentObjectID} total={this.state.totalFeels} store={this.props.store} closeFeelsPop={this.closeFeelsPopCallback}/>
                               }


                               <Route path="/s/:id" exact render={props => (<StoryPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname}/>)} />

                                <Route path="/p/:id" exact render={props => (<PostPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname}/>)} />


                               <Route path="/:id" exact render={props => (
                               isReservedPath(props.location.pathname)?
                               null
                                      : <ProfilePage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                )} />

                                <Route path="/:id/stories" exact render={props => (
                                  isReservedPath(props.location.pathname)?
                                 null
                                         : <ProfileStoriesPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                   )} />

                                <Route path="/:id/followers" exact render={props => (
                                isReservedPath(props.location.pathname)?
                               null
                                       : <ProfileFollowersPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                 )} />

                               <Route path="/:id/following" exact render={props => (
                                 isReservedPath(props.location.pathname)?
                                null
                                        : <ProfileFollowingPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                  )} />


                                <Route path="/:id/links" exact render={props => (
                                    isReservedPath(props.location.pathname)?
                                   null
                                           : <ProfileLinksPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                     )} />


                                   <Route path="/:id/xpage" exact render={props => (
                                         isReservedPath(props.location.pathname)?
                                        null
                                                : <ProfileXPagePage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                    )} />

                                    <Route path="/:id/xpage/:facet" exact render={props => (
                                          isReservedPath(props.location.pathname)?
                                         null
                                                 : <ProfileXPagePage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                     )} />




                                    <Route path="/:id/facets" exact render={props => (
                                      isReservedPath(props.location.pathname)?
                                     null
                                             :
                                         <ProfileFacetsPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                     )} />


                                   <Route path="/:id/facets/:f" exact render={props => (
                                             isReservedPath(props.location.pathname)?
                                            null
                                                    : <ProfileFacetPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                        )} />


                                  <Route path="/:id/headerthumb" exact render={props => (
                                      isReservedPath(props.location.pathname)?
                                     null
                                             : <ProfileHeaderThumb darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname} uploadThumbFromParent={this.relayCallbackHeader}/>
                                       )} />



                                <Route path="/" exact render={props => (
                                      <HomePage darkMode={this.state.darkMode}  store={this.props.store} path={props.location.pathname}/>
                                )} />


                                <Route path="/dl" exact render={props => ( <DownloadDebyooPage darkMode={this.state.darkMode}  store={this.props.store} pathname={props.location.pathname}/>)} />
                                <Route path="/careers" exact render={props => ( <CareersPage darkMode={this.state.darkMode} store={this.props.store} pathname={props.location.pathname}/>)} />
                                <Route path="/requestdemo" exact render={props => ( <RequestDemoPage darkMode={this.state.darkMode} store={this.props.store} pathname={props.location.pathname} countryCode={this.state.countryCode}/>)} />
                                <Route path="/contact" exact render={props => ( <ContactPage darkMode={this.state.darkMode} store={this.props.store} pathname={props.location.pathname} />)} />
                               <Route path="/our_mission" exact render={props => (<OurmissionPage darkMode={this.state.darkMode} store={this.props.store} pathname={props.location.pathname}/>)} />
                               <Route path="/privacy" exact render={props => (<PrivacyPage darkMode={this.state.darkMode} store={this.props.store} pathname={props.location.pathname}/>)} />
                               <Route path="/terms" exact render={props => ( <TermsPage darkMode={this.state.darkMode} store={this.props.store} pathname={props.location.pathname}/>)} />
                               <Route path="/eula" exact render={props => ( <EulaPage darkMode={this.state.darkMode} store={this.props.store} pathname={props.location.pathname}/>)} />

                                 <Route path="/song_page" exact render={props => ( <AboutSongPage darkMode={this.state.darkMode} store={this.props.store} pathname={props.location.pathname}/>)} />
                                  <Route path="/about/dshop" exact render={props => ( <AboutDShopPage darkMode={this.state.darkMode}  store={this.props.store} pathname={props.location.pathname}/>)} />
                                  <Route path="/about/dchat" exact render={props => ( <AboutDChatPage darkMode={this.state.darkMode}  store={this.props.store} pathname={props.location.pathname}/>)} />
                                <Route path="/about/covid19" exact render={props => ( <Covid19 darkMode={this.state.darkMode}  store={this.props.store} pathname={props.location.pathname}/>)} />

                                  <Route path="/support" exact render={props => ( <SupportPage darkMode={this.state.darkMode}  store={this.props.store} pathname={props.location.pathname}/>)} />
                                  <Route path="/support/systemstatus" exact render={props => ( <SupportSystemStatusPage darkMode={this.state.darkMode}  store={this.props.store} pathname={props.location.pathname}/>)} />


                                     <Route path="/about" exact render={props => (
                                            <Redirect to="/about/dshop" />
                                     )} />


                                     <Route path="/they-talk-about-us/:id" exact render={props => (<TheyTalkAboutUsPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname}/>)} />

                                       <Route path="/they-talk-about-us" exact render={props => (
                                              <Redirect to="/they-talk-about-us/togo-the-place-to-go" />
                                       )} />


                                       <Route path="/newsroom" exact render={props => (<NewsroomPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname}/>)} />

                                       <Route path="/newsroom/:y/:m/:t" exact render={props => (<NewsroomContentPage darkMode={this.state.darkMode} store={this.props.store} path={props.location.pathname}/>)} />



                          </div>

                      </div>
                 </BrowserRouter>
        	      }
        	    </Translation>
          );

    }

}




const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
 setLanguage:  (payload) => dispatch(setLanguage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
