export default function userProfile (state = {privateStories: []}, action = {})  {
  switch (action.type) {
    case "pushToPrivateStoriesToHide":
	let _arr = state.privateStories;
	//console.log("++++>>>> state: " + JSON.stringify(state.privateStories));
	_arr.push(action.payload)
	//	console.log("++++>>>> new state: " + JSON.stringify(_arr));
	//console.log("++++>>>> action.payload: " + JSON.stringify(action.payload));
      return {
        ...state,
	 privateStories: _arr
      };
    default:
      return state;
  }
};