export default function userFollowing (state = null, action = {})  {
  switch (action.type) {
    case "USER_FOLLOWING":
      return {
        ...state,
        followingArray: action.payload
      };
    default:
      return state;
  }
};