import { combineReducers } from "redux";
import { sessionService, sessionReducer } from 'redux-react-session';

import languageReducer from "./reducers/languageReducer";
import colorReducer from "./reducers/colorReducer";
import user from "./reducers/user";
import userFollowing from "./reducers/userFollowing";
import userProfile from "./reducers/userProfile";

/*import language from "./reducers/language";
//import user from "./reducers/user";
//import books from "./reducers/books";*/

export default combineReducers({
  session: sessionReducer,
  colors: colorReducer,
  user: user,
  language: languageReducer,
  userFollowing: userFollowing,
  userProfile: userProfile
  /*language,
  user: () => ({name: 'yves'}),
  books*/
});


/*export default (state, action) => {
  switch (action.type) {
    case "bgChange":
      return {
        ...state,
        bgColor: action.payload
      };
    case "colorChange":
      return {
        ...state,
        activeColor: action.payload
      };
    default:
      return state;
  }
};*/