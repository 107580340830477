import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { inject, observer, Provider } from "mobx-react";

import { sessionService, sessionReducer } from 'redux-react-session';

import configureStore from "./store";

import './index.css';
import './slick.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



const hist = createBrowserHistory();

export var store = configureStore();

// Init the session service
sessionService.initSessionService(store);

ReactDOM.render(

       <Provider store={store} history={hist}>
	      <App store={store} history={hist}/>
        </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
